<template>
  <div class="Login">
    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      center
      effect="dark">
    </el-alert>
    <div class="box">
      <div class="title">请登录</div>      
      <div class="context">
        <el-form
          class="form"
          :rules="loginRules"
          ref="loginForm"
          label-position="right"
          label-width="80px"
          size="medium"
          :model="formLogin"
        >
          <el-form-item class="input-label" label="手机号码" prop="username">
            <el-input
              v-model="formLogin.username"
              autocomplete="off"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item class="input-label" label="登录密码" prop="password">
            <el-input
              v-model="formLogin.password"
              type="password"
              autocomplete="off"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            class="input-label input-lable-code"
            label="验证码"
            prop="imgcode"
          >
            <el-input
              class="input input-code"
              maxlength="4"
              minlength="4"
              clearable
              autocomplete="off"
              v-model="formLogin.imgcode"
              @keyup.enter.native="onSubmitClick('loginForm')"
            ></el-input>
            <img
              class="imgcode"
              @click="onImageCodeClick"
              :src="api + '/home/code?t=' + random"
            />
          </el-form-item>
          <div class="btn-line">
            <el-button
              class="btn"
              type="primary"
              @click="onSubmitClick('loginForm')"
              >登录</el-button
            >
          </div>
          <div class="links">
            <!-- <router-link to="/pub/note">业务说明</router-link> | -->
            <router-link to="/pub/apply">注册帐号</router-link> |
            <router-link to="/pub/findpwd">忘了密码</router-link>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
var self = null;
import qs from 'qs'
export default {
  // name: "Login",
  data() {
    return {
      errorMsg: '',
      api: "",
      random: 0.123,
      formLogin: {
        username: "",
        password: "",
        imgcode: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          }
        ],
        password: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
        ],
        imgcode: [
          {
            required: true,
            message: "请输入正确的验证码",
            min: 4,
            max: 4,
            trigger: "blur",
          },
        ],
      },
      count: 0,
    };
  },
  created(opt) {
    self = this;
    // console.log(process.env)
    if (process.env.NODE_ENV === 'development') {
      this.formLogin.username = "18950010066"
      this.formLogin.password = "xjd989610"
      this.formLogin.imgcode = 'asdf'
    }
    this.onImageCodeClick();
    this.api = this.global.api;

    // console.log(this.global)
  },

  methods: {
    onSubmitClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log("验证通过");
          var pms = {
            username: this.formLogin.username,
            password: this.formLogin.password,            
            grant_type: "password",
            scope: "serv",
            client_id: this.global.client_id,
            client_secret: this.global.client_secret,
            // imgcode: self.formLogin.imgcode,
            // hash: this.random
          };
          // var pmstr = self.util.toQueryParamString(pms);
          self.loading.show();
          this.http.post_oauth("/oauth/token", pms, res=>{
            if (res.error || (res.code && res.code != 0)) {
              this.toast(res.error_description || res.msg);
              this.loading.hide();
            } else {
              if (!self.isEmpty(res.access_token)) {
                self.global.token = "Bearer " + res.access_token;                
                self.setItem("token", self.global.token);
                // console.log(self.get('token'))
                self.loadMyInfo();
              } else {
                this.loading.hide();
                // console.log(res)
                this.errorMsg = res.error_description
                if (res.error == 'image_code_error') { //可能是图片验证码过期了
                  this.onImageCodeClick();
                }
              }
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loadMyInfo() {
      // this.loading.show();
      var that = this
      this.post("/users/current", {},  (res) => {
        // that.loading.hide();
        if (res.code != 0) {
          that.$alert("获取登录信息失败，请稍后重试！", "出错了");
          return;
        }
        that.login = res.data;
        that.setItem("login", self.login);
        this.global.plat = that.login.plat;
        var txt = "登录成功！";
        // console.log(txt)
        // self.toast(txt);
        that.count ++

        // console.log('login_stat_event.....' + that.count);
        that.$emit("login_stat_event", { flag: true });
        // window.location.href = that.global.home + '/me/myhome'
      });
    },

    onImageCodeClick() {
      this.random = this.util.uuid();
    },
  },
};
</script>

<style scoped>
.box {
  min-height: 10vh;
  width: 30em;
  margin: 10vh auto;
  border: 1px solid #1f5081;
  border-radius: 15px;
}
.title {
  color: #fff;
  font-size: 1.5em;
  line-height: 3em;
  letter-spacing: 0.4em;
  height: 3em;
  background-color: #1f5081;
  border-radius: 15px 15px 0 0;
}
.context {
  text-align: left;
  padding: 2em;
}

.form {
  display: flex;
  flex-direction: column;
}
.el-input {
  width: 13em;
}
.input-label {
  margin-top: 0.3em;
}

.input {
  width: 18em;
}

.input-code {
  width: 6em;
  float: left;
}

.imgcode {
  float: left;
  height: 2.5em;
  margin-left: 0.2em;
}
.btn-line {
  display: flex;
}
.btn {
  width: 10em;
  margin-top: 2em;
  margin: 2em auto 0;
}

.links {
  margin-top: 20px;
  text-align: center;
}
</style>
